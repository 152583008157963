import React from "react"
import NotFound from "../containers/PageNotFound"
import Layout from "../components/layout";

const NotFoundPage = () => (
    <Layout>
        <NotFound/>
    </Layout>
)

export default NotFoundPage;